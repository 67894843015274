<template>
  <h2 class="page-title">
    <slot></slot>
  </h2>
</template>

<script>
export default {
  name: 'PageTitle'
}
</script>

<style scoped lang="scss">
  .page-title {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (max-width: 768px){
      font-size: 32px;
      line-height: 32px;
    }
  }
</style>
