import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    response: null,
    errors: [],
  },
  mutations: {
    RESPONSE: (state, payload) => {
      state.response = payload
    },
    ERRORS: (state, payload) => {
      state.errors = payload
    },
    CLEAR_ERRORS: (state) => {
      state.errors = []
    },
  },
  actions: {
    async API ({ commit }, { method, url, body = null, headers = null, preloader = false }) {
      console.log(preloader);
      try {
        return await Vue.prototype.$http[method](url, body, headers)
      } catch (e) {
        if (!e.response) return

        const data = e.response.data
        if (data && data.hasOwnProperty('server_error')) {
          commit('ERRORS', data && data.server_error)
        } else {
          commit('ERRORS', data)
        }
      }
    },
    async BORROWER_DOCUMENT_DATA ({ dispatch }, body) {
      return dispatch('API', {
        method: 'get',
        url: `documents_list/${body.hash || null}`
      })
    },
  },
  modules: {}
})
