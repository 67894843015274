import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:hash',
    name: 'documents',
    component: () => import(/* webpackChunkName: "payment-info" */ '../views/Documents.vue'),
    meta: {
      title: 'Документы по займу'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('CLEAR_ERRORS')
  next()
})

export default router
