import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faVk, faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';

import './styles/main.scss'

library.add(faVk, faTelegramPlane, faTimes, faFileAlt)

Vue.config.productionTip = false
Vue.component('fai', FontAwesomeIcon)

function getServer () {
  // eslint-disable-next-line no-undef
  return process.env.VUE_APP_SERVER_URL || document.location.origin
}

const instance = axios.create({
  baseURL: getServer() + '/api/v1/',
  responseType: 'json'
})

Vue.prototype.$http = instance

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
