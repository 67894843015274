<template>
  <div class="footer">
    <div class="container">
      <div class="footer__wrapper">
        <div class="footer__logo">
          <img src="@/assets/Logo.svg" alt="">
        </div>
        <div class="footer__copyright">
          Подробности условий выдачи займов узнавайте в отделениях.
          <br>
          © 2010 – {{new Date().getFullYear()}} Компании, работающие под знаком обслуживания «Деньга».
        </div>
        <div class="footer-menu">
          <div class="footer-menu__link">
            <a href="https://denga.ru/info" target="_blank" class="footer-menu__link">Заемщику</a>
          </div>
          <div class="footer-menu__link">
            <a href="https://denga.ru/promo/" target="_blank" class="footer-menu__link">Займы наличными</a>
          </div>
        </div>
        <div class="footer__socials">
          <socials />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Socials from '../Socials'
export default {
  components: {
    Socials
  }
}
</script>

<style scoped lang="scss">
  @import "@/styles/vars.scss";
  .footer {
    background: #F2F3F9;

    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-menu {
      &__link {
        @include ff_semibold;
        color: $color-dark-blue;
        text-decoration: none;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__logo {
      margin-right: 30px;

      @media screen and (max-width: 768px){
        margin-bottom: 20px;
      }
    }

    &__copyright {
      font-size: 12px;
      padding-right: 10px;
      line-height: 22px;

      @media screen and (max-width: 768px){
        margin-bottom: 20px;
      }
    }
  }

  .container {
    padding: 23px 32px;
    max-width: 1100px;
  }
</style>
